<template>
  <div>
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
      </el-header>
      <el-main style="padding: 0">
        <div class="funA-top">
          <div class="w">
            <div class="content">
              <div class="title"><img src="../../assets/images/Q/Q6/header-title-en.jpg" alt="" /></div>
              <div class="list">
                <ul>
                  <li @click="btn1">Homepage</li>
                  <li @click="btn2">Events</li>
                  <li @click="btn3">Organizers</li>
                </ul>
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  content="Only users certified as organizers can create an event">
                  <div class="btn" slot="reference" @click="btn4">
                    <i class="el-icon-s-flag"></i>
                    <span>Create Events for Free</span>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="form-box">
          <div class="w">
            <el-form :model="form.activityInfo" :rules="ruleForm" ref="ruleForm" label-width="3rem" label-position="left">
              <div class="form-card">
                <div class="title">Event Information<span style="font-size: 0.25rem;color: #8D8D8D;margin-left: 0.1rem">( For bilingual requirements, please input the title and event contents in both Chinese and English )</span></div>
                <div class="content">
                  <div class="act-title">
                    <el-form-item label="Event Title" prop="TITLE">
                      <el-input placeholder="For bilingual requirements, enter the following format：标题|Title" show-word-limit maxlength="100" v-model="form.activityInfo.TITLE"></el-input>
                    </el-form-item>
                  </div>
                  <div class="act-type">
                    <el-form-item label="Event Type" prop="CLASSIFY">
                      <el-select clearable  v-model="form.activityInfo.CLASSIFY" placeholder="Please Select">
                        <el-option
                          v-for="item in typeOptions"
                          :key="item.TWO"
                          :value="item.TWO">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <el-form-item label="Date & Time" prop="time">
                    <el-col :span="24">
                      <el-date-picker
                        width="100%"
                        v-model="form.activityInfo.time"
                        type="datetimerange"
                        range-separator="to"
                        start-placeholder="Starts"
                        end-placeholder="Ends"
                      ></el-date-picker>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="Location" prop="area">
                    <div class="old-address" v-show="addressShow" style="text-align: left">
                      <span>{{oldAddress}}</span>
                      <el-button @click="addressBtn" style="margin-left: 0.2rem">Change event region</el-button>
                    </div>
<!--                    <el-col :span="24" v-show="!addressShow" style="text-align: left">-->
<!--&lt;!&ndash;                      <v-distpicker @selected="onSelected" wrapper="area-sel" style="display: inline-block"></v-distpicker>&ndash;&gt;-->
<!--                    </el-col>-->
                    <el-col :span="24" v-show="!addressShow">
                      <el-col :style="{width:this.$route.query.id != undefined?'75%':'100%'}">
                        <el-input placeholder="Please enter the address" v-model="form.activityInfo.area" clearable></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-button v-show="this.$route.query.id != undefined" @click="addressBack" style="margin-left: 0.2rem">Clear all the changes</el-button>
                      </el-col>
                      <!--                      <div class="v-map" :style="{ height: mapHeight + 'px' }">-->
<!--                        <baidu-map :center="{ lng: 116.404, lat: 39.915 }" :zoom="15" :scroll-wheel-zoom="true" style="height: 100%">-->
<!--                          <bm-control style="width: 100%" :offset="{ width: '10px', height: '10px' }">-->
<!--                            <bm-auto-complete v-model="form.activityInfo.area" :sugStyle="{ zIndex: 1 }">-->
<!--                              <el-input @input="inputWatch" placeholder="请输入详细地址" v-model="form.activityInfo.area" clearable></el-input>-->
<!--                            </bm-auto-complete>-->
<!--                          </bm-control>-->
<!--                          <bm-view class="map" :style="{ height: (searchHeight == 0 ? mapHeight : mapHeight * 0.65) + 'px' }"></bm-view>-->
<!--                          <bm-local-search-->
<!--                            style="z-index: -1"-->
<!--                            @searchcomplete="searchcomplete"-->
<!--                            @infohtmlset="infoset"-->
<!--                            :selectFirstResult="true"-->
<!--                            class="map-search"-->
<!--                            :style="{ height: searchHeight }"-->
<!--                            :pageCapacity="3"-->
<!--                            :keyword="form.activityInfo.area"-->
<!--                            :autoViewport="true"-->
<!--                            :location="location"-->
<!--                          ></bm-local-search>-->
<!--                          <bm-marker class="bm-marker" :zIndex="99999999" :position="{ lng: this.lng, lat: this.lat }" :dragging="true" @dragend="areaMarker" animation="BMAP_ANIMATION_BOUNCE">-->
<!--                            <bm-label content="此标志可拖拽选择" :labelStyle="{ color: 'red', fontSize: '12px' }" :offset="{ width: -35, height: 30 }" />-->
<!--                          </bm-marker>-->
<!--                        </baidu-map>-->
<!--                      </div>-->
                    </el-col>
                  </el-form-item>
                  <el-form-item label="Cover" prop="actCover">
                    <el-upload
                      ref="upload"
                      class="upload-demo"
                      drag
                      :file-list="imageArr"
                      :limit="1"
                      :before-upload="beforeAvatarUpload"
                      :on-success="onSuccess"
                      :on-error="onError"
                      :on-exceed="overLimit"
                      action="/FH-WEB/web/savefile.do"
                      multiple
                      list-type="picture"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drag files here or click
                        <em>upload</em>
                        <div class="el-upload__tip" slot="tip">(We recommend using a image not less than 1080*640px but not larger than 4MB.)</div>
                      </div>
                    </el-upload>
                  </el-form-item>
<!--                  <el-form-item label="Event highlights" prop="SHORTCONTENT">-->
<!--                    <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.activityInfo.SHORTCONTENT"></el-input>-->
<!--                  </el-form-item>-->
                  <el-form-item label="Public or private event?" prop="actOpen">
                    <el-radio-group v-model="form.activityInfo.OPEN" :checked="checked">
                      <el-col :span="24" style="text-align: left">
                        <el-radio label="true">Public event should be reviewed before being displayed on the website</el-radio>
<!--                        <a>查看审核标准</a>-->
                      </el-col>
                      <el-col :span="24" style="text-align: left">
                        <el-radio label="false">Private page is only accessible to the people you specified.</el-radio>
                      </el-col>
                    </el-radio-group>
                  </el-form-item>
                  <!--                            <el-form-item>-->
                  <!--                              <el-button type="success" @click="saveInfo">保存活动信息</el-button>-->
                  <!--                            </el-form-item>-->
                </div>
              </div>
              <div class="form-card">
                <div class="title">Event Details</div>
                <div class="content">
                  <el-form-item class="m-left-0" prop="CONTENT">
                    <quill-editor ref="myQuillEditor" v-model="form.activityInfo.CONTENT" :options="editorOption"></quill-editor>
                  </el-form-item>
                  <!--                          <el-form-item>-->
                  <!--                            <el-button type="success" @click="onSubmit">保存详情</el-button>-->
                  <!--                          </el-form-item>-->
<!--                  <div id="editor" type="text/plain" style="width:1024px;height:500px;"></div>-->
                </div>
              </div>
              <div class="form-card">
                <div class="title">Registration Information</div>
                <div class="content">
                  <el-form-item label="Quantity available" prop="NUMBER" class="align-left-item">
                    <el-input class="enroll-num" v-model="form.activityInfo.NUMBER"></el-input>
                  </el-form-item>
<!--                  <el-form-item label="Subject to review or not?" prop="signExamine" class="align-left-item">-->
<!--                    <el-radio-group v-model="form.activityInfo.EXAMINE">-->
<!--                      <el-radio label="true">Yes</el-radio>-->
<!--                      <el-radio label="false">No</el-radio>-->
<!--                    </el-radio-group>-->
<!--                  </el-form-item>-->
                  <el-form-item label="Duration of Registration"  class="align-left-item line-height clear-indent" prop="effect">
                    <el-checkbox v-model="form.activityInfo.INFOCHECK">Sign up before the end of event<span style="color: rgb(141, 141, 141);margin-left: 10px">( Set the period for application )</span> </el-checkbox>
                    <el-date-picker
                      v-show="!form.activityInfo.INFOCHECK"
                      width="100%"
                      v-model="form.time1"
                      type="datetimerange"
                      range-separator="to"
                      start-placeholder="Starts"
                      end-placeholder="Ends"
                    ></el-date-picker>
                  </el-form-item>
<!--                  <el-form-item label="Registration information validity period" prop="infoEffect" class="align-left-item line-height clear-indent">-->
<!--                    <el-checkbox v-model="form.activityInfo.INFOTIME">Sign up before the end of event</el-checkbox>-->
<!--                    <el-date-picker-->
<!--                      v-show="!form.activityInfo.INFOTIME"-->
<!--                      width="100%"-->
<!--                      v-model="form.time2"-->
<!--                      type="datetimerange"-->
<!--                      range-separator="to"-->
<!--                      start-placeholder="Start"-->
<!--                      end-placeholder="End"-->
<!--                    ></el-date-picker>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item>&lt;!&ndash;                            <el-button type="success" @click="saveInfo">保存活动信息</el-button>&ndash;&gt;</el-form-item>-->
                </div>
              </div>
              <div class="form-card">
                <div class="title">Registration Table</div>
                <div class="content">
                  <div class="enroll-table">
                    <el-col :span="12">
                      <div class="must-sel">
                        <el-col :span="24">
                          <el-form-item class="m-left-0" prop="signName">
                            <el-checkbox :checked="true" disabled>Required</el-checkbox>
                            <span class="label">Name</span>
                            <el-input disabled v-model="form.registrationTable.name"></el-input>
                          </el-form-item>
                        </el-col>
                      </div>
                      <div class="must-sel">
                        <el-col :span="24">
                          <el-form-item class="m-left-0" prop="signEmail">
                            <el-checkbox :checked="true" disabled>Required</el-checkbox>
                            <span class="label">Email </span>
                            <el-input disabled type="email" v-model="form.registrationTable.email"></el-input>
                          </el-form-item>
                        </el-col>
                      </div>
<!--                      <div class="must-sel">-->
<!--                        <el-col :span="24">-->
<!--                          <el-form-item class="m-left-0" prop="signPhone">-->
<!--                            <el-checkbox :checked="false" disabled>Required</el-checkbox>-->
<!--                            <span class="label">phone</span>-->
<!--                            <el-input disabled  v-model="form.registrationTable.phoneNum"></el-input>-->
<!--                          </el-form-item>-->
<!--                        </el-col>-->
<!--                      </div>-->
                      <div class="txt clearfloat">Click the type of required fileds on the right to add new entry options
                        <div class="popup-btn" @click="popupBtn" style="margin-top: 0.5rem;"><i class="el-icon-question"></i><span>Filling Guide</span></div>
                      </div>
                      <div class="sel-box">
                        <div class="sel-item" v-for="(item, index) in form.registrationTable.typeData" :key="index">
                          <el-col :span="4"><el-checkbox title="Checkbox means the field is required" v-model="item.mustChecked">Required</el-checkbox></el-col>
                          <el-col :span="8"><el-input :title="item.tips1" :placeholder="item.placeholder1" class="item-input" v-model="item.title"></el-input></el-col>
                          <el-col :span="10"><el-input :title="item.tips2" placeholder="Enter the notes and tips" class="item-input" v-model="item.tips"></el-input></el-col>
                          <el-col :span="2" class="item-icon"><i @click="deleteType(index)" class="el-icon-delete"></i></el-col>
                          <el-col :span="20" :offset="4"><div class="tips-txt" v-html="item.tips4"></div></el-col>
                          <div style="width: 100%" v-if="item.selItem != undefined">
                            <el-col :span="20" :offset="4"><p class="sel-signTitle">Options</p></el-col>
                            <el-col :span="20" :offset="4">
                              <div class="sel-item-type">
                                <el-col :span="5" class="type-input" v-for="(item1, index1) in item.selItem" :key="index1">
                                  <el-input v-model="item1.txt" placeholder="Options" class="input" :title="item.tip3">
                                    <i slot="suffix" @click="deleteSel(index, index1)" class="el-input__icon el-icon-close"></i>
                                  </el-input>
                                </el-col>
                                <el-col :span="2">
                                  <div class="plus-icon"><i @click="plusSel(index)" class="el-icon-circle-plus"></i></div>
                                </el-col>
                              </div>
                            </el-col>
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="11" :offset="1">
                      <div class="sel-card">
                        <h2>Click the type of required fileds to the left to add new entry options</h2>
                        <div class="sel-list">
                          <ul>
                            <li @click="inputType('text')">Text</li>
                            <li @click="inputType('number')">Number</li>
                            <li @click="inputType('date')">Date</li>
                            <li @click="inputType('email')">Email</li>
                            <li @click="inputType('textarea')">Multi-line Text</li>
                            <li @click="inputType('radio')">Single-choice Button</li>
                            <li @click="inputType('checkbox')">Multiple-choice Button</li>
                            <li @click="inputType('select')">Drop-down Selection</li>
                          </ul>
                        </div>
                      </div>
                    </el-col>
                  </div>
<!--                  <el-form-item>&lt;!&ndash;                              <el-button type="success" @click="saveTable">保存报名表格</el-button>&ndash;&gt;</el-form-item>-->
                </div>
              </div>
              <el-form-item class="sub-btn" prop="agreement">
                <el-button type="success" @click="saveTable">Submit the form</el-button>
                <div class="agreement">
                  <el-checkbox v-model="form.checked"></el-checkbox>
                  <span>
                    I agree with
                    <router-link to="/AgreementEN" tag="a" target="_blank">the Registration Agreement for CityPlus Users</router-link>
                  </span>
                </div>
              </el-form-item>
            </el-form>
            <div class="popup-box" v-show="popupShow">
              <div class="popup-card">
                <div class="title">
                  Filling Guide <p style="font-size: 0.3rem;">( This column is designed to edit the application form. Please edit as needed.)</p>
                </div>
                <div class="content">
                  <img src="../../assets/images/Q/Q6/popup-en.jpg" alt="">
                </div>
                <div class="close-btn">
                  <button @click="popupBtn">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor, Quill } from 'vue-quill-editor'
import { addQuillTitle } from '../../static/quill-title-en'
import VDistpicker from 'v-distpicker'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import { registration, funsort, getActInfo, editActInfo, getUserinfo } from '../../api'
import qs from 'qs'

Quill.register('modules/ImageExtend', ImageExtend)

// import Quill from 'quill'
// import { ImageResize } from '../modules/ImageResize.js'
// Quill.register('modules/imageResize', ImageResize)
// import axios from 'axios'

export default {
  data () {
    var validate = (rule, value, callback) => {
      if (this.$refs.upload.uploadFiles.length === 0) {
        callback(new Error('Please upload the event cover'))
      } else {
        callback()
      }
    }
    var validate1 = (rule, value, callback) => {
      if (!this.addressShow && this.form.activityInfo.area == '') {
        callback(new Error('Please enter the address'))
      } else {
        callback()
      }
    }
    return {
      editor: null,
      popupShow: false,
      // pickerOptions: {
      //   shortcuts: [{
      //     text: '最近一周',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近一个月',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近三个月',
      //     onClick (picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       end.setTime(end.getTime() + 3600 * 1000 * 24 * 90)
      //       console.log(end.getTime())
      //       picker.$emit('pick', [start, end])
      //     }
      //   }]
      // },
      editorOption: {
        placeholder: 'For bilingual requirements,\n' +
          'Please edit both Chinese and English event contents in the following format:\n' +
          '活动内容\n' +
          'Event content',
        modules: {
          ImageExtend: {
            loading: true,
            name: 'file',
            size: 4, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: '/FH-WEB/web/savefile.do', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              console.log(res.result)
              return '/FH-WEB/' + res.result
            },
            // 图片超过大小的回调
            sizeError: () => {
              this.$message({
                showClose: true,
                message: 'The image must not exceed 4MB!',
                type: 'error',
                center: true
              })
            },
            // 可选参数 上传失败触发的事件
            error: () => {
              this.$message({
                showClose: true,
                message: 'Upload failed for network errors!',
                type: 'error',
                center: true
              })
            },
            headers: (xhr) => {
              // xhr.setRequestHeader('myHeader','myValue')
            }, // 可选参数 设置请求头部
            change: (xhr, formData) => {
              // xhr.setRequestHeader('myHeader','myValue')
              formData.append('TITLE', '101')
              formData.append('STARTTIME', 'times1')
            }
          },
          toolbar: {
            container: container,
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      },
      checked: '',
      addressShow: false,
      oldAddress: '',
      oldCityAddress: '',
      location: '',
      activityId: '',
      searchArea: '',
      typeOptions: [],
      typeValue: '',
      mapHeight: '455',
      mapHeight1: '100%',
      searchHeight: '0',
      selArea: '',
      lng: '113.358806',
      lat: '23.017166',
      info: '',
      imageArr: [],
      canSumbit: true,
      form: {
        time: '',
        time1: '',
        time2: '',
        checked: true,
        activityInfo: {
          USERID: '',
          STATE: 1,
          RECOMMEND: 'false',
          COLLECTION: 0,
          USER: '',
          area: '',
          time: '',
          HIDE: 'zh',
          STARTTIME: '',
          TITLE: '',
          ENDTIME: '',
          CITYADDRESS: '{"lng":116.403578,"lat":39.924095,"map":false}',
          ADDRESS: '',
          IMAGE: '',
          SHORTCONTENT: '',
          OPEN: 'true',
          CONTENT: '',
          NUMBER: '',
          EXAMINE: 'false',
          STARTTIME2: '',
          ENDTIME2: '',
          STARTTIME3: '',
          ENDTIME3: '',
          INFOCHECK: true,
          INFOTIME: true,
          THREE: '',
          TWO: '',
          TEXT: '',
          CLASSIFY: '',
          ONE: ''
        },
        registrationTable: {
          name: 'Name or nickname of applicant',
          phoneNum: 'Phone number of applicant',
          email: 'Email of applicant',
          typeData: [{
            type: 'number',
            mustChecked: false,
            placeholder1: 'Number',
            title: 'phone',
            tips: 'Phone number of applicant',
            tips1: 'Contents to be entered by user, eg:amount, phone number. ',
            tips2: 'Precautions for input. eg:Please enter the phone number.',
            // tips4: '适用于需要<span>填写较少文字</span>的项目(勾选必填则此项为必填项) '
          }]
        }
      },
      ruleForm: {
        TITLE: [
          { required: true, message: 'Please enter the event title', trigger: 'blur' }
        ],
        CLASSIFY: [
          { required: true, message: 'Please select the type', trigger: 'change' }
        ],
        time: [
          { required: true, message: 'Please select the event date and time', trigger: 'change' }
        ],
        actCover: [
          { validator: validate, required: true, message: 'Please upload the event cover', trigger: 'change' }
        ],
        area: [
          { validator: validate1, required: true, message: 'Please fill in detailed event address', trigger: 'blur' }
        ],
        SHORTCONTENT: [
          { required: true, message: 'Please fill in event highlights', trigger: 'change' }
        ],
        CONTENT: [
          { required: true, message: 'Please fill in event details', trigger: 'blur' }
        ],
        NUMBER: [
          { required: true, message: 'Please enter the quantity available', trigger: 'blur' }
        ]
      }
    }
  },
  inject: ['reload'],
  methods: {
    popupBtn () {
      this.popupShow = !this.popupShow
      if (this.popupShow) {
        $('body').css({ overflow: 'hidden' })
      } else {
        $('body').css({ overflow: 'auto' })
      }
    },
    beforeAvatarUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: 'Incorrect format. Please upload a JPG or PNG file.',
          type: 'error',
          center: true
        })
      }
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: 'The image must not exceed 4MB!',
          type: 'error',
          center: true
        })
      }
      return isTYPE && isLt2M
    },
    btn1 () {
      this.$router.push('/ActivitiesEN')
    },
    btn2 () {
      this.$router.push('/activitylistEN')
    },
    btn3 () {
      this.$router.push('/sponsorlistEN')
    },
    btn4 () {
      this.$router.push('/createEN')
    },
    addressBtn () {
      this.addressShow = false
    },
    addressBack () {
      this.addressShow = true
    },
    // 格式化默认时间
    gettimes (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth()
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      // var currentTime = year + '年' + month + '月' + day + '日  ' + hour + ':' + minute
      // var currentTime = 'new Date(' + year + ',' + month + ',' + day + ',' + hour + ',' + minute + ')'
      var currentTime = new Date(year, month, day, hour, minute)
      return currentTime
    },
    formatTime (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      var currentTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
      // var currentTime = 'new Date(' + year + ',' + month + ',' + day + ',' + hour + ',' + minute + ')'
      // var currentTime = new Date(year, month, day, hour, minute)
      return currentTime
    },
    // 详细地址监听
    inputWatch (e) {
      this.searchArea = e
      console.log(this.searchArea)
      console.log(e)
      // if (e === 'undefined') {
      //   if (this.selArea === '' || this.selArea === null) {
      //     console.log('请先选择地区')
      //   }
      // }
    },
    infoset (e) {
      // console.log(e)
      // this.area = e.address
      // this.area = this.selArea + e.title
      console.log(e)
      this.form.activityInfo.ADDRESS = this.selArea + e.title
      this.form.activityInfo.CITYADDRESS = JSON.stringify(e.point)
      this.lng = e.point.lng
      this.lat = e.point.lat
      console.log(this.form.activityInfo.ADDRESS)
      console.log(this.form.activityInfo.CITYADDRESS)
    },
    // 地图检索完成
    searchcomplete (e) {
      // console.log(e)
      if (e !== undefined) {
        this.mapHeight = '700'
        this.searchHeight = '35%'
      }
    },
    onSubmit () {
      // console.log('submit!')
      // // console.log($('.el-upload-dragger input'))
      // let file =  this.$refs.upload.getFile('1563795335827')
      // console.log(this.$refs.upload,file)
      // // console.log(this.$refs.upload.uploadFiles)
      // // let uploadlist = this.$refs.upload.uploadFiles;
      // // for(let i = 0,len = uploadlist.length;i<len;i++){
      // //   console.log(uploadlist[i].url)
      // // }
      // this.$refs.upload.submit((res)=>{
      //   console.log(res)
      // })
      //
      // console.log(this.checked)
      // console.log(this.form.activityInfo.ADDRESS)
      if (this.form.activityInfo.time !== '') {
        this.form.activityInfo.STARTTIME = this.formatTime(this.form.activityInfo.time[0])
        this.form.activityInfo.ENDTIME = this.formatTime(this.form.activityInfo.time[1])
      }
      if (this.form.time1 !== '') {
        this.form.activityInfo.STARTTIME2 = this.formatTime(this.form.time1[0])
        this.form.activityInfo.ENDTIME2 = this.formatTime(this.form.time1[1])
        // console.log(this.form.activityInfo.STARTTIME2)
        // console.log(this.form.activityInfo.ENDTIME2)
      }
      if (this.form.time2 !== '') {
        this.form.activityInfo.STARTTIME3 = this.formatTime(this.form.time2[0])
        this.form.activityInfo.ENDTIME3 = this.formatTime(this.form.time2[1])
        // console.log(this.form.activityInfo.STARTTIME3)
        // console.log(this.form.activityInfo.ENDTIME3)
      }
      console.log(this.form.activityInfo)
    },
    // 地区选择完成
    onSelected (address) {
      console.log(address)
      this.selArea = address.province.value + address.city.value + '-' + address.area.value
      this.form.activityInfo.area = address.province.value + address.city.value + address.area.value
      console.log(this.form.activityInfo.area)
    },
    html_decode (str) {
      var s = ''
      if (str.length == 0) {
        return ''
      }
      s = str.replace(/&amp;/g, '&')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      // eslint-disable-next-line no-useless-escape
      s = s.replace(/&#39;/g, "\'")
      s = s.replace(/&quot;/g, '"')
      s = s.replace(/<br\/>/g, '\n')
      return s
    },
    // 提交表单
    saveTable () {
      // this.$confirm('Event created successfully! Click the button below to skip to User Homepage.', 'Tips', {
      //   confirmButtonText: 'Confirm',
      //   type: 'success',
      //   center: true,
      //   showCancelButton: false,
      //   showClose: false,
      //   closeOnPressEscape: false,
      //   closeOnHashChange: false,
      //   closeOnClickModal: false
      // }).then(() => {
      //   this.$router.push('/userEN/eventadminEN')
      // })
      // this.form.activityInfo.CONTENT = this.html_decode(this.form.activityInfo.CONTENT)
      if (this.canSumbit) {
        if (this.form.activityInfo.time !== '') {
          this.form.activityInfo.STARTTIME = this.formatTime(this.form.activityInfo.time[0])
          this.form.activityInfo.ENDTIME = this.formatTime(this.form.activityInfo.time[1])
        }
        if (this.form.time1 !== '') {
          this.form.activityInfo.STARTTIME2 = this.formatTime(this.form.time1[0])
          this.form.activityInfo.ENDTIME2 = this.formatTime(this.form.time1[1])
          // console.log(this.form.activityInfo.STARTTIME2)
          // console.log(this.form.activityInfo.ENDTIME2)
        }
        if (this.form.time2 !== '') {
          this.form.activityInfo.STARTTIME3 = this.formatTime(this.form.time2[0])
          this.form.activityInfo.ENDTIME3 = this.formatTime(this.form.time2[1])
          // console.log(this.form.activityInfo.STARTTIME3)
          // console.log(this.form.activityInfo.ENDTIME3)
        }
        if (this.form.activityInfo.INFOCHECK) {
          this.form.activityInfo.STARTTIME2 = this.formatTime(this.form.activityInfo.STARTTIME)
          this.form.activityInfo.ENDTIME2 = this.formatTime(this.form.activityInfo.ENDTIME)
        }
        if (this.form.activityInfo.INFOTIME) {
          this.form.activityInfo.STARTTIME3 = this.formatTime(this.form.activityInfo.STARTTIME)
          this.form.activityInfo.ENDTIME3 = this.formatTime(this.form.activityInfo.ENDTIME)
        }
        console.log(this.form.activityInfo)
        // console.log(this.form.registrationTable)
        if (!this.addressShow) {
          this.form.activityInfo.ADDRESS = this.form.activityInfo.area
        }
        var str = JSON.stringify(this.form.registrationTable)
        this.form.activityInfo.THREE = str
        // this.info = qs.stringify(this.form.activityInfo)
        console.log(this.form.registrationTable)
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            // this.$refs.upload.submit()
            if (this.form.checked) {
              if (this.$route.query.id == undefined) {
                console.log('首次提交')
                // ==undefined  单次提交信息接口
                registration(qs.stringify(this.form.activityInfo)).then(res => {
                  console.log(res)
                  if (res.data.result === 'ok') {
                    this.$confirm('Event created successfully! Click the button below to skip to User Homepage.', 'Tips', {
                      confirmButtonText: 'Confirm',
                      type: 'success',
                      center: true,
                      showCancelButton: false,
                      showClose: false,
                      closeOnPressEscape: false,
                      closeOnHashChange: false,
                      closeOnClickModal: false
                    }).then(() => {
                      this.$router.push('/userEN/eventadminEN')
                    })
                  } else {
                    this.$message({
                      showClose: true,
                      message: 'Submit failed. Please check your network or contact administrator',
                      type: 'error',
                      center: true
                    })
                  }
                }).catch(e => {
                  this.$message({
                    showClose: true,
                    message: 'Request failed. Please check your network or contact administrator',
                    type: 'error',
                    center: true
                  })
                })
              } else {
                console.log('二次编辑')
                if (this.addressShow) {
                  this.form.activityInfo.ADDRESS = this.oldAddress
                  this.form.activityInfo.CITYADDRESS = this.oldCityAddress
                }
                // this.form.activityInfo.MAKEACTIVITY_ID = '894478cc84e74b919939680b4072505f'
                this.form.activityInfo.MAKEACTIVITY_ID = this.$route.query.id
                // this.form.activityInfo.push(actId)
                // console.log('二次编辑1')
                console.log(this.form.activityInfo)
                editActInfo(qs.stringify(this.form.activityInfo)).then((res) => {
                  console.log(res)
                  console.log('二次编辑1')
                  if (res.data.result === 'ok') {
                    this.$confirm('Edit succeeded, waiting for administrator review', 'Tips', {
                      confirmButtonText: 'Confirm',
                      type: 'success',
                      center: true,
                      showCancelButton: false,
                      showClose: false,
                      closeOnPressEscape: false,
                      closeOnHashChange: false,
                      closeOnClickModal: false
                    }).then(() => {
                      this.$router.push('/userEN/eventadminEN')
                    })
                  } else {
                    this.$message({
                      showClose: true,
                      message: 'Submit failed. Please check your network or contact administrator',
                      type: 'error',
                      center: true
                    })
                  }
                }).catch(e => {
                  this.$message({
                    showClose: true,
                    message: 'Request failed. Please check your network or contact administrator',
                    type: 'error',
                    center: true
                  })
                })
              }
            } else {
              this.$message({
                message: 'Please check and agree to the activity service agreement.',
                type: 'error',
                center: true,
                showClose: true
              })
            }
          } else {
            this.$message({
              message: 'Submit failed. Please fill in the required fields and try again.',
              type: 'error',
              center: true,
              showClose: true
            })
            return false
          }
        })
      } else {
        this.$confirm('You haven’t been authenticated as sponsor. Currently, only user authenticated as sponsor can initiate activity. Click the button below to enter the authentication page.', 'Info', {
          confirmButtonText: 'Get authentication now',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false,
          closeOnHashChange: false,
          closeOnClickModal: false
        }).then(() => {
          this.$router.push('/userEN/authenticationEN')
        })
      }
    },
    // 图片上传成功回调
    onSuccess (res) {
      console.log(res)
      let image = res.result
      this.form.activityInfo.ONE = '/FH-WEB/' + image
      // console.log(qs.stringify(this.form.activityInfo))
    },
    // 图片上传回调
    onError () {
      this.$message({
        showClose: true,
        message: 'Request failed. Please check your network or contact administrator',
        type: 'error',
        center: true
      })
    },
    overLimit () {
      // this.$message({
      //   showClose: true,
      //   message: '上传数量已上限，如需更改，请点击已上传图片右上角进行删除后上传新图片！',
      //   type: 'error',
      //   center: true
      // })
      this.$confirm(`You've already uploaded the maximum number of images. Please click the top right corner of uploaded images to delete and re-upload an image`, 'Info', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
      })
    },
    // 添加填写项
    inputType (type) {
      console.log(type)
      console.log(this.form.registrationTable.typeData)
      if (this.form.registrationTable.typeData.length < 8) {
        if (type === 'text') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Text',
            title: '',
            tips: '',
            tips1: 'Data to be filled in by user, eg:nickname, position, ID card, etc.',
            tips2: 'Filling requirements, eg:please fill in real ID card no.',
            tips4: 'Suitable for projects that require <span>less text</span> ( Required item, if checked, must be filled in ) '
          })
        } else if (type === 'number') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Number',
            title: '',
            tips: '',
            tips1: 'Contents to be entered by user, eg:amount, phone number. ',
            tips2: 'Precautions for input. eg:Please enter the phone number.',
            tips4: 'Suitable for projects that require <span>a number</span> to be filled in ( Required item, if checked, must be filled in ) '
          })
        } else if (type === 'date') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Date',
            title: '',
            tips: '',
            tips1: 'Fill in the purpose of date, eg:participation date, date of birth.',
            tips2: 'Precautions for filling. eg:the participation date must be valid.',
            tips4: 'Suitable for projects that require <span>a date</span> to be filled out ( Required item, if checked, must be filled in ) '
          })
        } else if (type === 'email') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Email Address',
            title: '',
            tips: '',
            tips1: 'Email items to be filled in by user, eg: work email, frequently used email',
            tips2: 'Precautions for input,eg: only domestic Email Address.',
            tips4: 'Suitable for projects that require <span>a mailbox</span> ( Required item, if checked, must be filled in ) '
          })
        } else if (type === 'textarea') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Multi-line text',
            title: '',
            tips: '',
            tips1: 'Contents to be filled in by user, eg:suggestions, self-introduction. ',
            tips2: 'Filling requirements, eg:no more than 200 words',
            tips4: 'Suitable for projects that require <span>more text</span> ( Required item, if checked, must be filled in ) '
          })
        } else if (type === 'radio') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Single-choice button',
            title: '',
            tips: '',
            tips1: 'Contents to be selected by user, eg:gender, student, ',
            tips2: 'Precautions for user selection. eg: Only one item to be selected',
            tip3: 'Edit the selected content. Click “+” to add option',
            tips4: 'Suitable for projects that require <span>single selection</span> ( Required item, if checked, must be filled in ) ',
            selItem: [
              {
                txt: ''
              }
            ]
          })
        } else if (type === 'checkbox') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Multiple-choice button',
            title: '',
            tips: '',
            tips1: 'Contents to be selected by user,eg: hobbies, subject. ',
            tips2: 'Precautions for user selection. eg: multiple items can be selected',
            tip3: 'Edit the selected content. Click “+” to add option',
            tips4: 'Suitable for projects that require <span>multiple selections</span> ( Required item, if checked, must be filled in ) ',
            selItem: [
              {
                txt: ''
              }
            ]
          })
        } else if (type === 'select') {
          this.form.registrationTable.typeData.push({
            type: type,
            mustChecked: false,
            placeholder1: 'Drop-down selection',
            title: '',
            tips: '',
            tips1: 'Contents to be selected by user, eg: week, class. ',
            tips2: 'Precautions for input, eg: only 1 day selected',
            tip3: 'Edit the selected content. Click “+” to add option',
            tips4: 'Suitable for projects that require <span>single selection and drop-down styles </span> ( Required item, if checked, must be filled in ) ',
            selItem: [
              {
                txt: ''
              }
            ]
          })
        }
      }
    },
    // 添加选项
    plusSel (e) {
      console.log(this.form.registrationTable.typeData[e].selItem)
      this.form.registrationTable.typeData[e].selItem.push({
        txt: ''
      })
    },
    // 删除选项
    deleteSel (index, index1) {
      if (this.form.registrationTable.typeData[index].selItem.length > 1) {
        this.form.registrationTable.typeData[index].selItem.splice(index1, 1)
      }
    },
    // 删除填写项
    deleteType (e) {
      console.log(e)
      this.form.registrationTable.typeData.splice(e, 1)
    },
    areaMarker (e) {
      // console.log(e.point)
      this.form.activityInfo.CITYADDRESS = JSON.stringify(e.point)
      console.log(this.form.activityInfo.CITYADDRESS)
      // console.log(e.point)
      var lonlat = { x: e.point.lng, y: e.point.lat }
      // console.log(lonlat.x)
      // console.log(lonlat.y)
      // console.log(this.convertLL2MC(lonlat))
      var newX = ''
      var newY = ''
      newX = this.convertLL2MC(lonlat)[0]
      newY = this.convertLL2MC(lonlat)[1]
      // console.log(newX)
      // console.log(newY)
      // axios.post('https://api.map.baidu.com/?qt=rgc&x=' + newX + '&y=' + newY + '&dis_poi=100&poi_num=10&latest_admin=1&ie=utf-8&oue=1&fromproduct=jsapi&res=api&callback=BMap._rd._cbk15419&ak=0c47ve1VaBp0ZXyjzQ98lFMKpGMM8q4p').then(res => {
      //   console.log(res)
      // })
      const _this = this
      $.ajax({
        url:
          'https://api.map.baidu.com/?qt=rgc&x=' +
          newX +
          '&y=' +
          newY +
          '&dis_poi=100&poi_num=10&latest_admin=1&ie=utf-8&oue=1&fromproduct=jsapi&res=api&callback=BMap._rd._cbk15419&ak=0c47ve1VaBp0ZXyjzQ98lFMKpGMM8q4p',
        type: 'POST',
        async: true, // 设置同步。ajax默认异步
        dataType: 'jsonp',
        jsonp: 'callback', // 传递给请求处理程序或页面的，用以获得jsonp回调函数名的参数名(默认为:callback)
        jsonpCallback: 'callback', // 自定义的jsonp回调函数名称，默认为jQuery自动生成的随机函数名
        timeout: 5000,
        contentType: 'application/json; charset=utf-8',
        success: function (result) {
          _this.form.activityInfo.area = result.content.address + result.content.poi_desc
          _this.form.activityInfo.ADDRESS = _this.area
        }
      })
      // console.log(this.form.activityInfo.ADDRESS)
      // console.log(this.form.activityInfo.CITYADDRESS)
    },
    // 百度经纬坐标系转百度米制经纬度坐标
    getRange (cC, cB, T) {
      if (cB != null) {
        cC = Math.max(cC, cB)
      }
      if (T != null) {
        cC = Math.min(cC, T)
      }
      return cC
    },
    getLoop (cC, cB, T) {
      while (cC > T) {
        cC -= T - cB
      }
      while (cC < cB) {
        cC += T - cB
      }
      return cC
    },
    convertor (cC, cD) {
      if (!cC || !cD) {
        return null
      }
      let T = cD[0] + cD[1] * Math.abs(cC.x)
      const cB = Math.abs(cC.y) / cD[9]
      let cE = cD[2] + cD[3] * cB + cD[4] * cB * cB + cD[5] * cB * cB * cB + cD[6] * cB * cB * cB * cB + cD[7] * cB * cB * cB * cB * cB + cD[8] * cB * cB * cB * cB * cB * cB
      T *= cC.x < 0 ? -1 : 1
      cE *= cC.y < 0 ? -1 : 1
      return [T, cE]
    },
    convertLL2MC (T) {
      var LLBAND = [75, 60, 45, 30, 15, 0]
      var LL2MC = [
        [
          -0.0015702102444,
          111320.7020616939,
          1704480524535203,
          -10338987376042340,
          26112667856603880,
          -35149669176653700,
          26595700718403920,
          -10725012454188240,
          1800819912950474,
          82.5
        ],
        [
          0.0008277824516172526,
          111320.7020463578,
          647795574.6671607,
          -4082003173.641316,
          10774905663.51142,
          -15171875531.51559,
          12053065338.62167,
          -5124939663.577472,
          913311935.9512032,
          67.5
        ],
        [
          0.00337398766765,
          111320.7020202162,
          4481351.045890365,
          -23393751.19931662,
          79682215.47186455,
          -115964993.2797253,
          97236711.15602145,
          -43661946.33752821,
          8477230.501135234,
          52.5
        ],
        [
          0.00220636496208,
          111320.7020209128,
          51751.86112841131,
          3796837.749470245,
          992013.7397791013,
          -1221952.21711287,
          1340652.697009075,
          -620943.6990984312,
          144416.9293806241,
          37.5
        ],
        [
          -0.0003441963504368392,
          111320.7020576856,
          278.2353980772752,
          2485758.690035394,
          6070.750963243378,
          54821.18345352118,
          9540.606633304236,
          -2710.55326746645,
          1405.483844121726,
          22.5
        ],
        [
          -0.0003218135878613132,
          111320.7020701615,
          0.00369383431289,
          823725.6402795718,
          0.46104986909093,
          2351.343141331292,
          1.58060784298199,
          8.77738589078284,
          0.37238884252424,
          7.45
        ]
      ]
      let cD, cC, len
      T.x = this.getLoop(T.x, -180, 180)
      T.y = this.getRange(T.y, -74, 74)
      const cB = T
      for (cC = 0, len = LLBAND.length; cC < len; cC++) {
        if (cB.y >= LLBAND[cC]) {
          cD = LL2MC[cC]
          break
        }
      }
      if (!cD) {
        for (cC = LLBAND.length - 1; cC >= 0; cC--) {
          if (cB.y <= -LLBAND[cC]) {
            cD = LL2MC[cC]
            break
          }
        }
      }
      const cE = this.convertor(T, cD)
      return cE
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(userInfo.userName == 'yk'){
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        closeOnClickModal: false
      }).then(() => {
        this.$router.push('/LoginEN')
      })
    } else {
      addQuillTitle()
      this.form.activityInfo.USERID = userInfo.userId
      // this.form.activityInfo.USERID = '793267160bf24a60882717065c044fcb'
      this.form.activityInfo.USER = userInfo.userName
      // this.form.activityInfo.time = [ new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10) ]
      funsort().then(res => {
        console.log(res.data)
        this.typeOptions = res.data
      })
      getUserinfo(userInfo.userName).then(res => {
        console.log(res, 1)
        if (res.data[0].STATE !== 'true') {
          this.canSumbit = false
          this.$confirm('You haven’t been authenticated as sponsor. Currently, only user authenticated as sponsor can initiate activity. Click the button below to enter the authentication page.', 'Info', {
            confirmButtonText: 'Get authentication now',
            type: 'warning',
            center: true,
            showCancelButton: false,
            showClose: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            closeOnClickModal: false
          }).then(() => {
            this.$router.push('/userEN/authenticationEN')
          })
        }
      })
      console.log(this.$route.query.id)
      if (this.$route.query.id != undefined) {
        console.log(1)
        let data = {
          MAKEACTIVITY_ID: this.$route.query.id
          // MAKEACTIVITY_ID: '894478cc84e74b919939680b4072505f'
        }
        getActInfo(qs.stringify(data)).then((res) => {
          console.log(res)
          this.form.activityInfo.CLASSIFY = res.data.new.CLASSIFY
          this.form.activityInfo.CONTENT = res.data.new.CONTENT
          this.form.activityInfo.EXAMINE = res.data.new.EXAMINE
          this.form.activityInfo.INFOCHECK = res.data.new.INFOCHECK == 'true'
          this.form.activityInfo.INFOTIME = res.data.new.INFOTIME == 'true'
          this.form.activityInfo.NUMBER = res.data.new.NUMBER
          this.form.activityInfo.OPEN = res.data.new.OPEN
          this.form.activityInfo.SHORTCONTENT = res.data.new.SHORTCONTENT
          this.form.activityInfo.TITLE = res.data.new.TITLE
          this.form.activityInfo.time = [this.gettimes(res.data.new.STARTTIME), this.gettimes(res.data.new.ENDTIME)]
          this.form.activityInfo.ONE = res.data.new.ONE
          this.form.activityInfo.ADDRESS = res.data.new.ADDRESS
          this.form.activityInfo.CITYADDRESS = res.data.new.CITYADDRESS
          this.form.activityInfo.area = res.data.new.ADDRESS
          this.oldAddress = res.data.new.ADDRESS
          this.oldCityAddress = res.data.new.CITYADDRESS
          this.addressShow = true
          this.form.activityInfo.RECOMMEND = res.data.new.RECOMMEND
          if (res.data.new.INFOCHECK != 'true') {
            this.form.time1 = [this.gettimes(res.data.new.STARTTIME2), this.gettimes(res.data.new.ENDTIME2)]
          }
          if (res.data.new.INFOTIME != 'true') {
            this.form.time2 = [this.gettimes(res.data.new.STARTTIME3), this.gettimes(res.data.new.ENDTIME3)]
          }
          let imgArr = {
            url: res.data.new.ONE
          }
          this.imageArr.push(imgArr)
          console.log(this.gettimes(res.data.new.STARTTIME))
          let arr = JSON.parse(res.data.new.THREE)
          this.form.registrationTable = arr
        })
      }
    }
  },
  components: {
    VDistpicker,
    quillEditor
  }
}
</script>
<style lang="less" scoped src="../../assets/css/CityQ6.less"></style>
<style lang="less">
  .form-box {
    .el-form-item__label{
      display: flex;
    }
    .m-left-0{
      .el-form-item__content{
        margin-left: 0!important;
      }
    }
  .el-checkbox:last-of-type{
    margin-right: 30px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    /*background-color: #00B6CD;*/
    /*border-color: #00B6CD;*/
  }
  .sub-btn {
    margin-top: 1rem;
    .el-checkbox {
      margin-right: 10px;
    }
    a {
      color: #00b6cd;
    }
  }
  .el-input__inner {
    padding: 0 10px;
  }
  .form-card {
    .content {
      .enroll-table .sel-box .sel-item .el-input{
        font-size: 12px;
      }
      .v-map {
        margin-top: 0.2rem;
        .map {
          /*height: 65%;*/
        }
        .map-search {
          overflow: auto;
        }
      }
      .ql-editor {
        min-height: 4rem;
        max-height: 10rem;
        overflow: auto;
      }
      .enroll-table {
        .must-sel {
          .el-input {
            width: 50%;
          }
        }
      }
      .area-sel {
        text-align: left;
      }
      .area-sel select {
        padding: 0.18rem 0.2rem;
        font-size: 0.25rem;
        color: #464a4c;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      }
      .el-date-editor--datetimerange.el-input,
      .el-date-editor--datetimerange.el-input__inner {
        width: 100%;
      }
      .el-upload {
        width: 100%;
        .el-upload-dragger {
          width: 100%;
          height: 200px;
        }
      }
      .el-radio-group {
        width: 100%;
        a {
          font-size: 0.25rem;
          /*color: #00b6cd;*/
        }
        .el-radio {
          padding: 0.2rem 0;
          font-size: 0.25rem;
        }
      }
      .ql-toolbar.ql-snow {
        text-align: left;
        .ql-picker{
          line-height: 24px;
        }
        .ql-picker-label::before{
          position: absolute;
        }
        .ql-formats {
          padding: 0.1rem 0;
        }
      }
      .line-height{
        .el-form-item__label{
          line-height: 20px;
        }
      }
      .align-left-item .el-form-item__content {
        text-align: left;
        .enroll-num {
          width: 10%;
        }
      }
    }
  }
  .act-type{
    .el-form-item__label{
      justify-content: center;
      text-align: center;
    }
    .el-select{
      display: block;
    }
  }
  .tips-txt{
    margin-top: 0.1rem;
    padding-left: 0.1rem;
    span{
      color: #ff4200;
    }
  }
}

</style>
