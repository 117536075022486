const titleConfig = {
  'ql-bold': 'Bold',
  'ql-color': 'Color',
  'ql-font': 'Font',
  'ql-code': 'Insert the code',
  'ql-italic': 'Italic',
  'ql-link': 'Add link',
  'ql-background': 'Background color',
  'ql-size': 'Font size',
  'ql-strike': 'Strikethrough',
  'ql-script': 'Superscript/subscript',
  'ql-underline': 'Underline',
  'ql-blockquote': 'Quote',
  'ql-header': 'Title',
  'ql-indent': 'indent',
  'ql-list': 'List',
  'ql-align': 'Text alignment',
  'ql-direction': 'Text direction',
  'ql-code-block': 'Code block',
  'ql-formula': 'Formula',
  'ql-image': 'image',
  'ql-video': 'video',
  'ql-clean': 'Clear font style'
}
export function addQuillTitle () {
  const oToolBar = document.querySelector('.ql-toolbar'),
    aButton = oToolBar.querySelectorAll('button'),
    aSelect = oToolBar.querySelectorAll('select')
  aButton.forEach(function (item) {
    if (item.className === 'ql-script') {
      item.value === 'sub' ? item.title = 'Superscript' : item.title = 'subscript'
    } else if (item.className === 'ql-indent') {
      item.value === '+1' ? item.title = 'Right indent' : item.title = 'Left indent'
    } else {
      item.title = titleConfig[item.classList[0]]
    }
    if (item.className === 'ql-header') {
      item.value === '1' ? item.title = 'Main title' : item.title = 'Sub title'
    }
    if (item.className === 'ql-list') {
      item.value === 'ordered' ? item.title = 'Number list' : item.title = 'Symbol list'
    }
  })
  aSelect.forEach(function (item) {
    item.parentNode.title = titleConfig[item.classList[0]]
  })
}
